.upperValue {
    display: block;
    font-size: 15px;
}

.lowerValue {
    display: block;
    font-size: 18px;
    padding-top: 8px;
}
