.headerContainer {
    background-color: #fff;
    border-bottom: 3px solid #ef344e;
    height:120px;
}

.headerContent {
    width: 100%;
    padding: 28px 30px;
}

.headerLogo > * {
    height: 64px;
}
