* {
    box-sizing: border-box;
}

body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #064eaa;
}
a:active {
    color: inherit;
}

form {
    width: 100%;
}

ul {
    padding-left: 18px;
}

ul > li {
    margin: 12px 0;
}

h1 {
  font-size: 1.7rem;
  font-weight: 400;
}

.noShadow {
    box-shadow: none !important;
}

.noRadius {
    border-radius: 0 !important;
}


.MuiInputBase-root.MuiFilledInput-root {
    background-color: rgba(255, 255, 255, 0.9);
}
.MuiInputBase-root.MuiFilledInput-root:hover {
    background-color: rgba(255, 255, 255, 1);
}
.MuiInputBase-root.MuiFilledInput-root.Mui-focused,
.MuiInputBase-root .MuiSelect-select:focus {
    background-color: rgba(255, 255, 255, 1);
}

.MuiFormControl-root,
.MuiInputBase-root {
    width: 100%;
}

.MuiFormLabel-root.MuiInputLabel-root {
    line-height: inherit; /* fix focus jitter */
}

.MuiFormControl-root.noBackground .MuiFilledInput-root.Mui-disabled {
    background: none;
}

.MuiFormControl-root.noBackground .MuiFilledInput-root.Mui-disabled::before {
    border: none;
}

/*  Set https://github.com/jfcaiceo/react-lightbox-component z-index higher then app-bar and navigation*/

.lightbox-backdrop {
    z-index: 9999 !important;
}

.lightbox-img-thumbnail--cubit {
    display: inline-block;
    background-size: cover;
    background-position: center;
    position: relative;
}

.MuiGrid-grid-xs-true {
    min-width: 0; /* fix auto width issues */
}

button {
  font: inherit;
}

.hop-to-content {
  background: #3B65A3;
  display: flex;
  padding: 20px;
}

.hop-to-content-link {
  color: #FFFFFF;
}

.error {
  color: #b52424;
  margin: 14px;
}
