.container {
  display:        flex;
  flex-direction: column;
  position:       fixed;
  height:         100vh;
  width:          100%;
  z-index:        100;
  overflow:       scroll;
  background:     rgba(50,60,80,0.65);
  padding:        20px;
  justify-content: center;
}
.child {
  display:        flex;
  flex-direction: column;
  align-items:   center;
  background:    #FFFFFF;
  border-radius: 3px;
  min-height:    100px;
  padding:       12px;
  overflow:      scroll;

}
.close {
  float: right;
}
